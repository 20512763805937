// @flow
import * as React from 'react'
import styled from 'styled-components'
import { config } from 'react-awesome-styled-grid'
import { colors, H1, P, AnchorCTA, GlobalStyle } from '@components/shared'
import BackgroundGradient from '@components/BackgroundGradient'

export default function Custom404 (): React.Node {
  return (
    <>
      <GlobalStyle backgroundColor={colors.midnightBlue} />
      <Container>
        <BackgroundGradient
          quadrant={1}
          overlayColor='magenta'
        />
        <CenterWrapper>
          <ErrorHeader color={colors.sand} textAlign="center">Hmm... that page doesn’t exist.</ErrorHeader>
          <ErrorText color={colors.sand} textAlign="center">We want you lost in fantasy, not lost in space. Return to the homepage to continue browsing.</ErrorText>
          <ErrorBtnWrapper>
            <AnchorCTA href="/">Back To Home</AnchorCTA>
          </ErrorBtnWrapper>
        </CenterWrapper>
      </Container>
    </>
  )
}

const ErrorHeader = styled(H1)`
  margin-bottom: 32px;
  font-size: 60px;
  line-height: 110%;
  ${(props: any): string => config(props).media.md`
    margin-top: 114px;
  `}
  ${(props: any): string => config(props).media.lg`
    margin-bottom: 45px;
    margin-top: 0;
    font-size: 90px;
    line-height: 105%;
  `}
`
const ErrorText = styled(P)`
  margin-bottom: 60px;
  font-size: 16px;
  line-height: 150%;
  ${(props: any): string => config(props).media.lg`
    margin-bottom: 64px;
  `}
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  ${(props: any): string => config(props).media.md`
    justify-content: center;
  `}
`
const CenterWrapper = styled.div`
  text-align: center;
  padding: 20px;
  margin: 120px auto 0;
  ${(props: any): string => config(props).media.md`
    max-width: 475px;
    margin: 0 auto;
  `}
  ${(props: any): string => config(props).media.lg`
    max-width: 670px;
  `}
`
const ErrorBtnWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  > a {
    width: 200px;
  }
`
